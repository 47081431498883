<template>
  <el-dialog
    title="编辑合同模板"
    @close="close('ruleForm')"
    @open="loadData"
    :visible="dialogVisible"
  >
    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-width="80px"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.name"
        ></el-input>
      </el-form-item>

      <el-form-item label="描述" prop="desc">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.desc"
        ></el-input>
      </el-form-item>

      <el-form-item label="内容" prop="content">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.content"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import moment from 'moment';
import { api } from '/src/api/base';
//新增接口
export const ContractTemplateAdd = api('/contractTemplate')(
  'contractTemplate.add.json'
);
//查询列表接口
export const ContractTemplateList = api('/contractTemplate')(
  'contractTemplate.list.json'
);
//查询详情接口
export const ContractTemplateDetail = api('/contractTemplate')(
  'contractTemplate.describe.json'
);
//编辑接口
export const ContractTemplateEdit = api('/contractTemplate')(
  'contractTemplate.edit.json'
);
//删除接口
export const ContractTemplateDel = api('/contractTemplate')(
  'contractTemplate.del.json'
);
import * as _ from 'lodash';
export default {
  components: {
    //Upload: () => import("/src/components/upload.vue")
  },
  data () {
    return {
      dialogFormVisible: false,
      ruleForm: {
        name: '',

        desc: '',

        content: ''
      },
      rules: {
        name: [],

        desc: [],

        content: []
      }
    };
  },
  props: {
    code: String,
    dialogVisible: Boolean
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');
          const params = {};
          Object.keys(this.ruleForm).forEach(key => {
            const it = this.ruleForm[key];
            if (_.isDate(it)) {
              params[key] = moment(it).format('YYYY-MM-DD HH:mm');
              return;
            }
            if (_.isArray(it)) {
              params[key] = it.join(',');
              return;
            }
            params[key] = it;
          });
          ContractTemplateEdit({ ...params, code: this.code }).then(res => {
            console.log(res);
            this.closeDialog();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close (formName) {
      this.$refs[formName].resetFields();
      this.closeDialog();
    },
    closeDialog () {
      this.$emit('update:dialogVisible', false);
    },
    async loadData () {
      const result = await ContractTemplateDetail({ code: this.code });
      Object.keys(this.ruleForm).forEach(key => {
        this.ruleForm[key] = result[key];
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
</style>
